export const CONTRACT_TEMPLATE_TYPE = 'Contracts';
export const REQUEST_DOCUMENT_TEMPLATE_TYPE = 'Request documents';
export const ACKNOWLEDGE_TEMPLATE_TYPE = 'Acknowledge';

export const TEMPLATE_TYPES = [
    {
        value: CONTRACT_TEMPLATE_TYPE,
        label: 'Contract',
    },
    {
        value: REQUEST_DOCUMENT_TEMPLATE_TYPE,
        label: 'Request document'
    },
    {
        value: ACKNOWLEDGE_TEMPLATE_TYPE,
        label: 'Request acknowledgement of receipt'
    }
];
